ul.obis-site-language-menu {
  list-style: none;
  padding: 13px 0;
  margin: 0;

  &>li {
    display: inline-block;
    margin: 0 5px;

    &>a {
      &>img {
        max-width: 30px;
        height: auto;
      }
    }
  }
}