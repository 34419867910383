@mixin blue-background-gradient-horizontal() {
  background: $blue;
  background: linear-gradient(90deg, $blue 0%, $lightblue 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e2e75', endColorstr='#0588cc', GradientType=1);
}

@mixin blue-background-gradient-vertical() {
  background: $blue;
  background: linear-gradient(to bottom, $blue 0%, $lightblue 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e2e75', endColorstr='#0588cc', GradientType=1);
}

@mixin gray-background-gradient-radial() {
  background: $dark;
  background: radial-gradient(ellipse at center, $medium-dark 0%, $dark 100%);
}

@mixin blue-background-gradient-horizontal-opaque() {
  background: rgba($blue, 0.7);
  background: linear-gradient(90deg, $blue 0%, rgba($blue, .85) 60%, rgba($lightblue, .3) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d91e2e75', endColorstr='#4d0588cc', GradientType=1);
}

@mixin blue-background-gradient-vertical-opaque() {
  background: rgba($blue, 0.7);
  background: linear-gradient(to bottom, $blue 0%, rgba($blue, 0.85) 50%, rgba($lightblue, 0.3) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d91e2e75', endColorstr='#4d0588cc', GradientType=0);
}

@mixin gray-background-gradient-radial-opaque() {
  background: $dark;
  background: radial-gradient(ellipse at center, rgba(87, 87, 86, 1) 0%, rgba(43, 43, 43, 0.4) 100%);
}

@mixin blue-right-upper-corner-diagonal-gradient() {
  background: transparent;
  background: linear-gradient(45deg, rgba($blue, 0) 0%, rgba($blue, 0) 50%, rgba($blue, 0.8) 70%, rgba($blue, 1) 100%);
}

@mixin _fontawesome($content:'') {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;

  &::before {
    content: $content;
  }
}

@mixin fontawesome-solid($content:'') {
  @include _fontawesome($content);

  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

@mixin fontawesome-regular($content:'') {
  @include _fontawesome($content);

  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

@mixin fontawesome-brands($content:'') {
  @include _fontawesome($content);

  font-family: "Font Awesome 5 Brands";
}

@mixin _glyphicon($content) {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  content: $content;
}

@mixin glyphicons($content:'') {
  font-family: 'Glyphicons Regular';
  vertical-align: top;

  @include _glyphicon($content);
}

@mixin glyph-fileicons($content:'') {
  font-family: 'Glyphicons Filetypes';
  position: relative;
  top: 1px;

  @include _glyphicon($content);
}

@mixin glyph-halflings($content:'') {
  font-family: 'Glyphicons Halflings';
  position: relative;
  top: 1px;

  @include _glyphicon($content);
}

@mixin glyph-social($content:'') {
  font-family: 'Glyphicons Social';
  position: relative;
  top: 1px;

  @include _glyphicon($content);
}

@mixin clearfix {

  &::before,
  &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin custom-clearfix {
  display: block;
  clear: both;
  content: " ";
}

@mixin dynamic-background-preconfig {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

@mixin fake-column($size, $align: left) {
  // position: relative;
  width: 100% / 12 * $size;
  float: $align;
  min-height: 1px;
}

@mixin slide-height-mixin {
  min-height: 600px;
  max-height: 100vh;
  height: 70vh;
}

@mixin flexbox-container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@mixin flexbox-stretch-container {
  display: flex;
  align-content: stretch;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
}

@mixin general-headline-styling {
  h1 {
    font-size: 6em;
    font-weight: 800;
    margin: 10px 0;
  }

  h2 {
    font-size: 5em;
    font-weight: 700;
    margin: 10px 0;
  }

  h3 {
    font-size: 4em;
    font-weight: 600;
    margin: 5px 0;
  }

  h4 {
    font-size: 3em;
    font-weight: 300;
    margin: 5px 0;
  }

  h5 {
    font-size: 2em;
    font-weight: 300;
    margin: 5px 0;
  }
}