.obis-site-accessibility-icon {
  max-width: 50px;
  max-height: 50px;

  &+div.tooltip {
    font-size: 10pt;

    .tooltip-inner {
      @include blue-background-gradient-vertical;
    }

    .tooltip-arrow {
      border-bottom-color: $blue;
    }
  }
}