@font-face {
  font-family: 'Glyphicons Regular';
  src: url('../../../../../_Resources/Static/Packages/Obis.Site/Fonts/glyphicons-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Glyphicons Filetypes';
  src: url('../../../../../_Resources/Static/Packages/Obis.Site/Fonts/glyphicons-filetypes-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../../../../../_Resources/Static/Packages/Obis.Site/Fonts/glyphicons-halflings-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Glyphicons Social';
  src: url('../../../../../_Resources/Static/Packages/Obis.Site/Fonts/glyphicons-social-regular.ttf') format('truetype');
}