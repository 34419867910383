.neos-nodetypes-image {
  figure.neos-alignment-left img {
    margin-right: auto;
  }

  figure.neos-alignment-center img {
    margin-left: auto;
    margin-right: auto;
  }

  figure.neos-alignment-right img {
    margin-left: auto;
  }
}
