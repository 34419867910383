#CTA-teaser-container {
  display: none;

  position: fixed;
  right: -50.0px;
  top: calc(50% - 100px);
  z-index: 9999999999;

  transform: scale(1) translate(0px);
  transition: transform 3s cubic-bezier(0.075, 0.82, 0.165, 1);

  .CTA-teaser-inner {
    &>img {
      max-width: 150px;
      height: auto;

      cursor: pointer;
      filter: drop-shadow(-3px 5px 6px rgba(0, 0, 0, 0.6));
    }
  }

  &:hover {
    transform: scale(1.1) translateX(-5px);
  }
}

.neos-backend #CTA-teaser-container {
  display: none !important;
}
