html {
  body {
    font-size: 16px;
    font-family: 'Source Sans Pro', sans-serif;

    template {
      display: none !important;
    }

    section.header-slider {
      .header-image {
        @include dynamic-background-preconfig();
        @include flexbox-container();

        position: relative;
        width: 100%;
        height: 500px;
        max-height: 100vh;

        &>.neos-contentcollection {
          @include general-headline-styling;

          min-width: 100px;
          position: relative;
          z-index: 1;

          h1 {
            font-size: 3em;
          }

          h2 {
            font-size: 2.5em;
          }

          h3 {
            font-size: 2em;
          }

          h4 {
            font-size: 1.5em;
          }

          h5 {
            font-size: 1em;
          }
        }
      }
    }
  }
}