section.content-section {
  .section-overlay-wrapper {
    padding: 35px 10px;

    &.color-light {
      color: $medium-dark;
      background-color: $light;
    }

    &.color-dark {
      color: $light;

      @include gray-background-gradient-radial;
    }

    &.color-blue {
      color: $light;

      @include blue-background-gradient-horizontal;
    }

    &.color-white {
      color: $medium-dark;
      background-color: $white;
    }

    &.opaque {
      &.color-dark {
        @include gray-background-gradient-radial-opaque;
      }

      &.color-blue {
        @include blue-background-gradient-horizontal-opaque;
      }
    }
  }
}