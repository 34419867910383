div.obis-site-cardgallery {
  margin: 15px 0;

  .gallery-inner {
    @include flexbox-stretch-container;

    justify-content: space-evenly;

    @media (min-width: $screen-sm-min) {
      justify-content: space-between;
    }

    .card-item {
      min-height: 200px;
      min-width: 200px;
      max-height: 350px;
      max-width: 500px;

      width: 99%;
      margin-bottom: 1%;

      @media (min-width: $screen-sm-min) {
        width: 49%;
        max-width: unset;
      }

      @media (min-width: $screen-md-min) {
        width: 46%;
      }

      &:before {
        content: "";
        float: left;
        padding-top: 100%;
      }

      &.color-dark {
        background-color: $medium-dark;
        color: $light;
      }

      &.color-light {
        background-color: $light;
        color: $medium-dark;
      }

      &.color-blue {
        // background-color: $blue;
        color: $light;

        @include blue-background-gradient-horizontal;
      }

      &.color-white {
        background-color: $white;
        color: $medium-dark;
      }

      &.obis-site-cardgallery-card {
        .card-inner {
          position: relative;
          height: 100%;
          width: 100%;

          @include dynamic-background-preconfig;

          .content-wrapper {
            @include flexbox-container;

            position: relative;
            flex-direction: column;
            height: 100%;
            width: 100%;
            padding: 5px;

            &>.neos-contentcollection {
              @include general-headline-styling;

              min-width: 100px;

              h1 {
                font-size: 3.5em;
                margin: 5px 0;
              }

              h2 {
                font-size: 3em;
                margin: 5px 0;
              }

              h3 {
                font-size: 2.5em;
                margin: 5px 0;
              }

              h4 {
                font-size: 2em;
                margin: 5px 0;
              }

              h5 {
                font-size: 1.5em;
                margin: 5px 0;
              }
            }

            .color-blue,
            .color-dark,
            .color-light {
              transition: all 0.3s ease-in-out;
            }

            &:hover {

              .color-blue,
              .color-dark {
                color: $light;
                text-shadow: 0 0 3px $medium-dark;
              }

              .color-light {
                // color: $blue;
                color: $medium-dark;
                text-shadow: 0 0 3px $light;
              }
            }
          }
        }
      }

      &.obis-site-cardgallery-flipcard {
        max-height: 300px;

        .card-inner {
          position: relative;
          height: 100%;
          width: 100%;

          .frontend,
          .backend {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            @include flexbox-container;

            backface-visibility: hidden;
            transition: transform 0.4s ease-in-out;
            padding: 15px;
          }

          .frontend {
            transform: rotateY(0deg);

            .obis-site-headline {
              &>span {
                display: block;
                font-size: 2em;
                font-weight: bold;
                margin: 10px 0;
                text-transform: uppercase;
              }
            }
          }

          .backend {
            transform: rotateY(180deg);
          }

          &:hover {
            .frontend {
              transform: rotateY(180deg);
            }

            .backend {
              transform: rotateY(0deg);
            }
          }
        }
      }

      &.obis-site-cardgallery-productcard {
        height: 400px;
        max-width: 270px;

        box-shadow: 0 0 0 0 $blue;
        transition: box-shadow 0.3s ease-in-out;

        &:hover {
          box-shadow: 0 0 0 5px $blue;
        }

        @media (min-width: $screen-xs-min) {
          max-width: 230px;
        }

        @media (min-width: $screen-md-min) {
          width: 24%;
        }

        @media (min-width: $screen-lg-min) {
          max-width: 230px;
        }

        .card-inner {
          position: relative;
          height: 100%;
          width: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          background-color: $blue;

          .card-appendix {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $blue;
            color: white;
            font-size: 15pt;
            min-height: 50px;
            padding: 10px 3px;
            text-align: center;
          }
        }
      }

      &.obis-site-cardgallery-icon-corner-card {
        background: unset;
        color: $light;
        max-height: 450px;

        .card-inner {
          position: relative;
          height: 400px;
          border-radius: 15px;

          background-color: $blue;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;

          .icon-container {
            width: 200px;
            height: 200px;
            position: absolute;
            right: 0;
            top: 0;

            @include blue-right-upper-corner-diagonal-gradient;
            border-top-right-radius: 15px;

            & > img {
              height: auto;
              margin-left: auto;
              margin-right: 10px;
              margin-top: 10px;
              max-width: 50px;
            }
          }
        }

        .card-appendix {
          color: $blue;
          font-size: 15pt;
          font-weight: 900;
          height: 50px;
          padding: 10px;
          text-align: center;
          text-decoration: underline;
        }
      }

      &.align-top {
        .card-inner .content-wrapper {
          justify-content: flex-start;
        }
      }

      &.align-middle {
        .card-inner .content-wrapper {
          justify-content: center;
        }
      }

      &.align-bottom {
        .card-inner .content-wrapper {
          justify-content: flex-end;
        }
      }
    }
  }
}
