header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 999;

  .header-inner-wrapper {
    background: $white;
    padding: 15px 0;

    .header-inner {
      .header-inner-left-block {
        @include fake-column(8);

        @media (min-width: $screen-sm-min) {
          @include fake-column(4);
        }

        .home-link {
          display: block;
          max-width: 200px;
        }
      }

      .header-inner-right-block {
        @include fake-column(8);

        // margin-top: 20px;

        @media (max-width: $screen-sm-max) {
          display: none;
        }

        &>* {
          display: block;
          float: right;
        }

        .obis-site-searchbox {
          padding: 6px 0px;
        }

        .homelink {
          color: $blue;
          margin-right: 10px;
          padding: 16px 0;
        }

        .obis-site-mainmenu {
          padding: 15px 0;
        }

        .obis-site-searchbox+.obis-site-mainmenu {
          transition: max-width 0.3s ease;
          max-width: 100%;
          overflow: hidden;
        }

        .obis-site-searchbox.active+.obis-site-mainmenu {
          max-width: 0%;
          max-height: 45px;
        }

        .obis-site-language-menu {
          margin-left: 5px;
        }
      }

      .header-inner-right-block-mobile {
        @include fake-column(4);

        @media (min-width: $screen-sm-min) {
          display: none;
        }

        button.hamburger {
          position: absolute;
          right: 20px;
          z-index: 999;

          .hamburger-inner,
          .hamburger-inner::after,
          .hamburger-inner::before {
            background-color: $blue;
          }

          &.is-active {

            .hamburger-inner,
            .hamburger-inner::after,
            .hamburger-inner::before {
              background-color: $medium-dark;
            }
          }
        }

        .home-link {
          display: block;
          left: 15px;
          max-width: 200px;
          opacity: 1;
          position: absolute;
          transition: opacity 0.3s ease-in-out 0.1s;
          z-index: 999;
        }

        button.hamburger:not(.is-active) + .home-link {
          opacity: 0;
        }
      }
    }
  }
}