/* Breadcrumb menu for product-pages */

.obis-site-product-page {

  .obis-site-product {

    .breadcrumb {

      text-align: left;
      background-color: #ffffff;
      margin-top: 5px;
      margin-bottom: 5px;

      li {
        font-family: inherit;
        //font-size: 1.5em;

        @media (max-width: $screen-xs-max) {
          font-size: 1.0em;
        }

        @media (min-width: $screen-sm-min) {
          font-size: 1.2em;
        }

        font-weight: 600;

        color: $blue;

        a {
          color: $blue;
          transition: all 0.3s ease-out 0s;
        }

        &:hover a {
          color: $blue;
        }
      }

      li a::after {
        content: "\003e";
        display: inline-block;
        margin-left: 12px;
        margin-right: 10px;
        color: $blue;
      }

      li:first-child a::before {
        display: inline-block;
        color: $blue;
        margin-right: 10px;
        transition: all 0.3s ease-out 0s;
      }

      li:first-child {
        a {
          color: $blue;
          transition: all 0.3s ease-out 0s;
        }

        &:hover a {
          color: $blue;
        }

        &:hover a::before {
          color: $blue;
        }
      }

      li::before {
        content: '' !important;
        display: none;
      }

      li.current {
        text-decoration: underline;
      }

    }

  }

}