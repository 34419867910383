footer {
  .map-container {
    .map-wrapper {
      position: relative;

      .google-map-canvas {
        width: 100%;
        height: 500px;
      }
    }
  }

  .footer-contact-wrapper {
    background: $light;
    padding: 30px 0;

    .contact {
      .obis-site-footer-information {
        display: block;
        padding: 1px;
        margin-bottom: 15px;

        .footer-title,
        span[data-__neos-property="footerTitle"] {
          font-weight: bold;
          margin-bottom: 25px;
          margin-top: 0;

          &>p {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .footer-wrapper {
    background: $blue;
    color: $white;

    .download-link {
      cursor: pointer;
    }

    .file-download-inner {
      position: relative;

      .download-link {
        .more-info {
          padding: 50px 0;

          .download-teaser-text {
            font-size: 22pt;
            text-transform: uppercase;
            font-weight: 600;
          }

          .pdf-icon {
            max-height: 130px;
            margin: 0 0 0 auto;
          }

          .obis-site-dark-overlay {}
        }
      }
    }

    .meta {
      padding: 15px 0;
      font-size: 11pt;
      background: $dark;

      .copyright-notice {
        padding: 5px;
        display: block;
      }

      .footer-menu-link {
        color: $light;
      }
    }
  }
}