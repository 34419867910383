/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-LightItalic.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-LightItalic.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-LightItalic.ttf') format('truetype');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-LightItalic.ttf') format('truetype');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-LightItalic.ttf') format('truetype');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-LightItalic.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-LightItalic.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Italic.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Italic.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Italic.ttf') format('truetype');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Italic.ttf') format('truetype');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Italic.ttf') format('truetype');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Italic.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Italic.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-BoldItalic.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-BoldItalic.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-BoldItalic.ttf') format('truetype');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-BoldItalic.ttf') format('truetype');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-BoldItalic.ttf') format('truetype');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-BoldItalic.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-BoldItalic.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-BlackItalic.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-BlackItalic.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-BlackItalic.ttf') format('truetype');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-BlackItalic.ttf') format('truetype');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-BlackItalic.ttf') format('truetype');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-BlackItalic.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-BlackItalic.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-ExtraLight.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-ExtraLight.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-ExtraLight.ttf') format('truetype');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-ExtraLight.ttf') format('truetype');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-ExtraLight.ttf') format('truetype');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-ExtraLight.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-ExtraLight.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Light.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Light.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Light.ttf') format('truetype');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Light.ttf') format('truetype');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Light.ttf') format('truetype');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Light.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Light.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Regular.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Regular.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Regular.ttf') format('truetype');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Regular.ttf') format('truetype');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Regular.ttf') format('truetype');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Regular.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Regular.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-SemiBold.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-SemiBold.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-SemiBold.ttf') format('truetype');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-SemiBold.ttf') format('truetype');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-SemiBold.ttf') format('truetype');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-SemiBold.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-SemiBold.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Bold.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Bold.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Bold.ttf') format('truetype');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Bold.ttf') format('truetype');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Bold.ttf') format('truetype');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Bold.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Bold.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Black.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Black.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Black.ttf') format('truetype');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Black.ttf') format('truetype');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Black.ttf') format('truetype');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Black.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'), url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/SourceSansPro-Black.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}