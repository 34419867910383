div.obis-site-multi-link-button {
  margin-bottom: 15px;

  button.dropdown-button {
    border: 1px solid;
    border-color: $blue;
    color: $white;
    display: block;
    font-size: 15pt;
    font-weight: 900;
    font-weight: bold;
    min-width: 120px;
    outline: none !important;
    padding: 8px 25px;
    text-align: center;
    text-transform: uppercase;

    @include blue-background-gradient-horizontal;

    &.align-center,
    &.align-justify {
      margin: 0 auto;
    }

    &.align-right {
      margin-left: auto;
    }

    i.fas {
      margin-left: 10px;
    }
  }

  ul.dropdown-menu {
    left: 0;
    right: 0;

    @include blue-background-gradient-horizontal;
    border-color: $blue;

    &>li {
      &>a {
        color: $white;
        transition: background-color 0.2s ease;
        font-size: 18px;

        &:hover,
        &:focus {
          cursor: pointer;
          // background-color: $blue;
          background-color: $blue;
        }
      }
    }

    &.mega-menu {
      &>li {
        width: 33%;
        float: left;

        &:last-of-type {
          float: right;
        }
      }
    }

    &.huge-menu {
      &>li {
        width: 50%;
        float: left;

        &:last-of-type {
          float: right;
        }
      }
    }

    &.default-menu {
      &>li {
        width: 100%;
        float: none;
        text-align: center;
      }
    }

    &::after {
      @include custom-clearfix;
    }
  }
}