body.obis-site-blog-article {
  section.article-wrapper {
    position: relative;

    .article-date {
      position: absolute;
      right: 0;
      top: 30px;
      font-size: 13pt;
      font-weight: 600;
    }
  }
}