.obis-site-icon-counter {
  align-items: center;
  display: flex;
  flex-direction: column;

  .icon-wrapper {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: center;
    width: 100px;

    .icon-img {
      max-width: 100px;
      max-height: 75px;
      width: auto;
    }
  }

  .counter-wrapper {
    .counter-component {
      font-size: 35pt;
      font-weight: 900;
    }
  }

  .content-wrapper {
    padding: 5px;
    width: 100%;
    font-size: 18pt;
    text-align: center;

    .bar {
      height: 4px;
      width: 80px;
      background-color: $medium-dark;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }
}