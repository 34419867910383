.obis-site-product {
  .image-col {
    @include fake-column(12);

    @media (min-width: $screen-sm-min) {
      @include fake-column(6);

      min-height: 500px;
    }

    img {
      display: block;
      // height: 100%;
      max-width: 100%;
      width: auto;
      margin: 0 auto;

      @media (max-width: $screen-sm-max) {
        max-height: 300px;
      }
    }
  }

  .info-col {
    @include fake-column(12);

    padding-top: 50px;

    @media (min-width: $screen-sm-min) {
      @include fake-column(6);
    }

    .product-title {
      font-size: 23pt;
      font-weight: 900;
      margin-bottom: 10px;
    }

    .product-description {
      font-size: 15pt;
      font-weight: 600;
      margin-bottom: 25px;
    }

    .product-article-number {
      margin-bottom: 25px;
    }

    .product-article-number,
    .product-article-number p {
      font-size: 15pt;
      font-weight: 700;
    }

    .accessibility-icons {
      @include flexbox-container;

      // justify-content: space-between;
      justify-content: flex-start;
      margin: 30px 0;

      .obis-site-accessibility-icon {
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }

    @media (max-width: $screen-sm-max) {
      .button-wrapper {
        button.obis-site-button {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  &::after {
    @include custom-clearfix;
  }
}