.ajax-form {
    .ajax-content {
        &>form#contact-form {
            .form-control {
                border-radius: 0;
                box-shadow: inset -3px 3px 10px -5px $black;
            }
            .option-light {
                color: #999999;
            }
            .option-light:hover {
                color: #555555;
            }
            fieldset#contact-form-first-row {
                fieldset#contact-form-section-info {}
                fieldset#contact-form-section-message {
                    textarea#contact-form-message {
                        min-height: 180px;
                        resize: vertical;
                    }
                }
            }
            fieldset#contact-form-second-row {
                fieldset#contact-form-section-captcha {}
                fieldset#contact-form-section-compliance {
                    .checkbox {
                        margin: 0;
                    }
                }
            }
            .button-actions {
                .actions {
                    nav.form-navigation {
                        ul.nav {
                            float: none !important;
                            li.submit {
                                float: none !important;
                                display: block;
                                text-align: center;
                                &>button.btn {
                                    width: calc(100% + 30px);
                                    border-radius: 0;
                                    margin-left: -15px;
                                    color: $black;
                                    background-color: $white;
                                    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
                                    &:hover {
                                        color: $white;
                                        background-color: $blue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    p.noconsent-notice {
        font-size: 1em;
        b {
            font-size: 1.1em;
        }
        a {
            color: $blue;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.color-blue .ajax-form p.noconsent-notice a {
    color: $white;
}