// Breakpoints
$screen-xs-min: 425px !default;
$screen-sm-min: 768px !default;
$screen-md-min: 992px !default;
$screen-lg-min: 1200px !default;
$screen-xs-max: $screen-xs-min - 1 !default;
$screen-sm-max: $screen-sm-min - 1 !default;
$screen-md-max: $screen-md-min - 1 !default;
$screen-lg-max: $screen-lg-min - 1 !default;

// Colors
$blue: #004b99;
$lightblue: lighten($blue, 10%);
$darkblue: darken($blue, 10%);

// Shades
$dark: #2b2b2b;
$medium-dark: #575756;
$light: #e2e2e2;
$shadow: #a6a6a6;
$black: #000;
$white: #fff;
$overlay: rgba($black, 0.85);

// Set css variables
:root {
  --blue: $blue;
  --dark: $dark;
  --medium: $medium-dark;
  --light: $light;
  --shadow: $shadow;
}