nav.obis-site-mainmenu {
  color: $blue;

  ul.nav.navbar-nav {
    margin-right: 0;

    li.menu-item {
      cursor: pointer;
      line-height: 25px;
      margin: 0 2px;

      a.menu-link {
        font-size: 14pt;
        font-weight: 600;
        background-color: transparent;
        padding: 3px 7px;
        margin-bottom: 5px;

        transition: color 200ms ease-in-out;

        &:hover {
          color: $blue;
        }
      }

      ul.submenu {
        list-style: none;
        padding: 0;
        overflow: hidden;
        position: fixed;
        background: $white;

        li.menu-item {
          font-weight: normal;
          margin: 0;

          a.menu-link {
            display: block;
            width: 100%;
            color: $medium-dark;
            background-color: transparent;
            margin-bottom: 0;

            transition: color 200ms ease-in-out,
              background-color 200ms ease-in-out;

            &:hover {
              background-color: $blue;
              color: $white;
            }
          }
        }
      }
    }

    li.major-menu-item:last-child {
      text-align: right;
    }
  }
}