main.obis-site-product-category {
  section.product-slider-wrapper {
    // padding: 35px 15px 25px;

    .product-slider {
      position: relative;

      .slider-contents {
        padding: 0 55px;

        .product-item {
          display: inline-block;
          width: 33%;
          text-align: center;

          &>* {
            margin-bottom: 5px;
          }

          .image-container {
            align-items: center;
            display: flex;
            height: 300px;
            justify-content: center;
            width: 100%;
          }

          .product-title {
            align-items: center;
            display: flex;
            font-size: 14pt;
            font-weight: 900;
            height: 53px;
            justify-content: center;
            text-transform: uppercase;
          }

          .product-description {
            align-items: center;
            display: flex;
            font-size: 11pt;
            font-weight: 600;
            height: 60px;
            justify-content: center;
            padding: 0 2px;
            text-overflow: ellipsis;

            &>p:last-of-type {
              margin-bottom: 0;
            }
          }

          .product-article-number {
            margin-bottom: 25px;
          }

          .product-article-number,
          .product-article-number p {
            font-size: 11pt;
            font-weight: 700;
          }


          .obis-site-button {
            font-size: 12pt;
            text-transform: none;

            color: $blue;
            transition: color 300ms ease-in-out;

            &:hover {
              color: $blue;
            }
          }
        }
      }

      .slider-controls {
        button.slider-button {
          position: absolute;
          top: calc(50% - 21px);
          color: $light;

          background: none;
          outline: none !important;

          margin: 0;
          padding: 0;
          font-size: 40px;
          line-height: 40px;

          border: 2px solid $light;
          border-radius: 50%;
          padding: 0px;
          width: 55px;
          height: 55px;
          text-align: center;

          color: $dark;
          border-color: $dark;

          &.button-prev {
            left: 0;
            padding-right: 5px;
          }

          &.button-next {
            right: 0;
            padding-left: 5px;
          }
        }
      }
    }
  }

  section.all-products-wrapper {
    padding: 35px 15px 25px;

    .product-category-item {
      margin-bottom: 15px;

      &:not(:last-of-type) {
        border-bottom: 1px solid $blue;
      }

      .content-inner {
        position: relative;
        padding: 15px 10px;

        .image-wrapper {
          @include fake-column(3);

          padding: 5px;

          .product-image {
            display: block;
            width: auto;
            height: auto;
            max-height: 150px;

            margin-left: auto;
            margin-right: 15px;
          }
        }

        .text-wrapper {
          @include fake-column(9);

          padding: 5px 15px;
          position: relative;

          .product-title {
            font-size: 11pt;
            font-weight: 900;
            text-transform: uppercase;
          }

          .product-description {
            font-size: 10pt;
            font-weight: 600;
          }

          .product-article-number,
          .product-article-number p {
            font-size: 10pt;
            font-weight: 700;
          }

        }

        i.more-icon {
          position: absolute;
          right: 15px;
          top: calc(50% - 15px);
          font-size: 40px;
          line-height: 40px;
          color: $blue;
        }

        &::after {
          @include custom-clearfix;
        }
      }
    }

    nav.page-navigation {
      ul.neos-widget-paginator {
        list-style: none;
        padding: 0;
        text-align: center;

        &>li {
          display: inline-block;
          font-size: 14pt;
          color: $blue;
          margin: 0 5px;

          &.current {
            font-weight: bold;
            border-bottom: 2px solid $blue;
          }

          &.previous {}

          &.next {}
        }
      }
    }
  }
}