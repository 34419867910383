.obis-site-linked-image {
  .container-inner {
    .image {
      & > img {
        margin: 0 auto;
      }
    }

    .text {
      color: $blue;
      font-size: 18pt;
      font-weight: 900;
      margin-bottom: 15px;
      margin-top: 15px;
      text-align: center;
      text-decoration: underline;
    }
  }
}